export default function useClickHandler() {
    const { gtag } = useGtag();

    let retryCount = 0;

    interface EventData {
        Distributor: string;
        Manufacturer: string;
        PartNumber: string;
        CurrencyCode: string;
        SearchSource: string;
        Hash?: string;
    }

    interface EventCompany {
        id: string;
        name: string | null;
    }

    function ResultsLinkClicked(source: HTMLElement, label: string, resetRetries = false) {
        const globalConfig = useStateGlobalConfig();
        const currentRegion = useCurrentRegion();

        if (resetRetries) {
            retryCount = 0;
        }

        const isPartDetailImage = label == "PartDetailImage" || label == "PartDetailImageDistributorName";
        const data = isPartDetailImage ? GetPartDetailImageEventData(source) : GetPartEventData(source);

        // Log the click locally by sending request to site's click controller.
        const distributor = GetEventDistributor(data);
        const manufacturer = GetEventManufacturer(data);

        // If the results aren't on the page yet, lets give the ajax call another second before logging the click;
        if (!parseInt(distributor.id, 10) && retryCount < 5 && isPartDetailImage) {
            setTimeout(function () {
                ResultsLinkClicked(source, label);
            }, 1000);

            retryCount++;
            return;
        }

        // Log to Google analytics.
        if (label == "AllResults") {
            gtag("event", "Link", { event_category: "Distributor", event_label: "All Results" });
            gtag("event", "distributor_referral_all_results", { distributor_name: distributor.name });
        } else {
            gtag("event", "Link", { event_category: "Product", event_label: label });

            const eventData = {
                distributor_name: distributor.name,
                manufacturer_name: manufacturer.name,
                part_number: data.PartNumber,
            };

            switch (label) {
                case "PartDetailImage":
                case "PartDetailImageDistributorName":
                    gtag("event", "distributor_referral_part_image", eventData);
                    break;

                case "Part":
                case "Datasheet":
                case "Buy":
                case "View":
                default:
                    gtag("event", "distributor_referral_" + label.toLowerCase(), eventData);
                    break;
            }
        }

        // Log as a conversion for Google Ads if it is a part detail image, part, buy, or view click (qualifies for PPC).
        const cpcLabels = ["Part", "Buy", "View", "AllResults"];
        if (isPartDetailImage || cpcLabels.indexOf(label) >= 0) {
            gtag_report_conversion();
        }

        // Send json request to log click.
        // TODO: should clickhandler SearchedParts param be renamed?
        axios.post("/api/part-click", {
            DistributorId: distributor.id,
            DistributorName: distributor.name?.trim(),
            ManufacturerId: manufacturer.id,
            ManufacturerName: manufacturer.name?.trim(),
            SearchedParts: [{ Token: globalConfig.value.SearchText || data.PartNumber }],
            ClickedPartNumber: data.PartNumber,
            LinkType: isPartDetailImage ? "Part" : label,
            Source: data.SearchSource || "Website", // Must match a source in Ecia.Authorized.Shared.AnalyticsConstants
            Hash: data.Hash,
            CurrencyCode: data.CurrencyCode || globalConfig.value.CurrencyCode,
            LanguageCode: globalConfig.value.LanguageCode,
            RegionId: currentRegion.value?.RegionId,
        });

        return true;
    }

    // Event snippet for Distributor Lead conversion.
    function gtag_report_conversion() {
        gtag("event", "conversion", {
            send_to: "AW-392178872/ycKXCN_zxZcYELjZgLsB",
        });
    }

    function GetEventDistributor(data: EventData): EventCompany {
        const distributorMatches = data.Distributor.match(/^(.+)\[(\d+)\]$/);
        return {
            id: distributorMatches && distributorMatches.length > 2 ? distributorMatches[2] : "0",
            name: distributorMatches && distributorMatches.length > 1 ? distributorMatches[1].trim() : null,
        };
    }

    function GetEventManufacturer(data: EventData): EventCompany {
        const manufacturerMatches = data.Manufacturer.match(/^(.+)\[(\d*)\]$/);
        return {
            id: manufacturerMatches && manufacturerMatches.length > 2 ? manufacturerMatches[2] : "",
            name: manufacturerMatches && manufacturerMatches.length > 1 ? manufacturerMatches[1].trim() : "",
        };
    }

    function GetPartEventData(source: Element): EventData {
        const data: EventData = {
            Distributor: "",
            Manufacturer: "",
            PartNumber: "",
            CurrencyCode: "",
            SearchSource: "",
            Hash: "",
        };

        if (source) {
            const details = (source.closest("[data-dist]") || source.closest("[data-mfr]")) as HTMLElement;
            data.Distributor = details?.dataset.dist || "";
            data.Manufacturer = details?.dataset.mfr || "";
            data.PartNumber = details?.dataset.mpn || "";
            data.CurrencyCode = details?.dataset.cur || "";
            data.SearchSource = details?.dataset.source || "";
            data.Hash = details?.dataset.hash || "";
        }
        return data;
    }

    function GetPartDetailImageEventData(source: HTMLElement): EventData {
        const data = {
            Distributor: "",
            Manufacturer: "",
            PartNumber: "",
            CurrencyCode: "",
            SearchSource: "",
            Hash: "",
        };

        if (source) {
            const distId = source.dataset.dist;

            document.querySelectorAll("#ExactMatchesTable>tbody>tr").forEach((x) => {
                if (!(x instanceof HTMLElement)) {
                    return;
                }

                const dist = x.dataset.dist?.split("[")?.pop()?.split("]").shift();
                const isDuplicate = x.dataset.isDistributorDuplicate?.toLowerCase();
                if (isDuplicate == "false") {
                    if (dist == distId) {
                        if (!data.Distributor) {
                            data.Distributor = x.dataset.dist || "";
                        }
                        if (!data.Manufacturer) {
                            data.Manufacturer = x.dataset.mfr || "";
                        }
                        if (!data.PartNumber) {
                            data.PartNumber = x.dataset.mpn || "";
                        }
                        if (!data.CurrencyCode) {
                            data.CurrencyCode = x.dataset.cur || "";
                        }
                        if (!data.SearchSource) {
                            data.SearchSource = x.dataset.source || "";
                        }
                        if (!data.Hash) {
                            data.Hash = x.dataset.hash || "";
                        }
                    }
                }
            });
        }

        return data;
    }

    function ClickEventListener(event: MouseEvent) {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const elem = event.target.closest("a");
        if (elem && elem.href) {
            const gaDataset = elem.dataset.ga;
            if (gaDataset) {
                const data = gaDataset.split(":");

                // The default label is "Website", unless another label is present
                const eventLabel = data.length > 2 ? data[2] : "Website";

                switch (data[0]) {
                    case "s":
                        ResultsLinkClicked(elem, data[1], true);
                        break;
                    case "m":
                        // GA4 logging
                        gtag("event", "manufacturer_referral", {
                            manufacturer_name: GetEventManufacturer(GetPartEventData(elem)).name,
                        });

                        break;
                    case "d":
                        // GA4 logging
                        gtag(
                            "event",
                            eventLabel == "Manufacturer"
                                ? "distributor_referral_manufacturer"
                                : "distributor_referral_participating",
                            {
                                distributor_name: GetEventDistributor(GetPartEventData(elem)).name,
                            }
                        );

                        break;
                    case "r":
                        // UA event logging
                        gtag("event", "Link", { event_category: "Navigation", event_label: eventLabel });

                        // GA4 logging
                        gtag("event", "other_referral", { location: elem.href });

                        break;

                    case "c":
                        // Cookie policy events
                        gtag("event", `cookies_${data[1]}`);

                        break;
                }
            } else if (elem.href.match(/^[a-z]{3,}:\/\//i)) {
                const i = elem.href.indexOf(document.location.host);
                if (!(i > 3 && i < 10)) {
                    // ignore absolute local links
                    gtag("event", "Link", { event_category: "Navigation", event_label: "Website" });
                }
            }
        }
    }

    return ClickEventListener;
}
