<template>
    <form @submit.prevent="searchNavigate(searchTerm)">
        <ButtonGroup>
            <input
                id="footerSearchText"
                v-model="searchTerm"
                type="text"
                class="grow border-none rounded focus:ring-transparent"
                :aria-label="$t('Layout.SearchButtonTitle')"
                maxlength="100"
                :placeholder="$t('Layout.SearchPartNumberDefaultText')" />
            <Button
                id="searchSubmitButtonFooter"
                variant="white"
                fill="solid"
                class="focus-visible:ring-offset-0"
                :title="$t('Layout.SearchButtonTitle')">
                <Icon name="magnifying-glass" class="text-gray-400" />
            </Button>
        </ButtonGroup>
    </form>
</template>
<script setup lang="ts">
const globalConfig = useStateGlobalConfig();
const searchTerm = ref(globalConfig.value.SearchText);
const searchNavigate = useSearchNavigate();
</script>
